import React from "react";

const Profile = () => {
  return (
    <section className="main-container">
      <div className="container">
        <h2 className="text-center mb-5">Profile Page Coming Soon!</h2>
      </div>
    </section>
  );
};

export default Profile;
