/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/plans.scss";

const Plans = () => {
  const navigate = useNavigate();
  const handlePaymentType = (id) => {
    navigate("/payments", { id });
  };

  return (
    <section className="pricing-section">
      <div className="container">
        <div className="sec-title text-center">
          <h2>Choose a Plan</h2>
        </div>

        <div className="outer-box">
          <div className="row">
            <div className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
              <div className="inner-box">
                <div className="icon-box">
                  <div className="icon-outer">
                    <i className="fas fa-paper-plane"></i>
                  </div>
                </div>
                <div className="price-box">
                  <div className="title"> Day Pass</div>
                  <h4 className="price">$4.99</h4>
                </div>
                <ul className="features">
                  <li className="true">Conference plans</li>
                  <li className="true">Free Lunch And Coffee</li>
                  <li className="true">Certificate</li>
                  <li className="false">Easy Access</li>
                  <li className="false">Free Contacts</li>
                </ul>
                <div className="btn-box">
                  <a
                    onClick={() => {
                      handlePaymentType("DAY");
                    }}
                    className="theme-btn"
                  >
                    BUY plan
                  </a>
                </div>
              </div>
            </div>

            <div
              className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <div className="icon-outer">
                    <i className="fas fa-gem"></i>
                  </div>
                </div>
                <div className="price-box">
                  <div className="title">Week Pass</div>
                  <h4 className="price">$29.99</h4>
                </div>
                <ul className="features">
                  <li className="true">Conference plans</li>
                  <li className="true">Free Lunch And Coffee</li>
                  <li className="true">Certificate</li>
                  <li className="true">Easy Access</li>
                  <li className="false">Free Contacts</li>
                </ul>
                <div className="btn-box">
                  <a
                    onClick={() => {
                      handlePaymentType("WEEk");
                    }}
                    className="theme-btn"
                  >
                    BUY plan
                  </a>
                </div>
              </div>
            </div>

            <div
              className="pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
              data-wow-delay="800ms"
            >
              <div className="inner-box">
                <div className="icon-box">
                  <div className="icon-outer">
                    <i className="fas fa-rocket"></i>
                  </div>
                </div>
                <div className="price-box">
                  <div className="title">Month Pass</div>
                  <h4 className="price">$99.99</h4>
                </div>
                <ul className="features">
                  <li className="true">Conference plans</li>
                  <li className="true">Free Lunch And Coffee</li>
                  <li className="true">Certificate</li>
                  <li className="true">Easy Access</li>
                  <li className="true">Free Contacts</li>
                </ul>
                <div className="btn-box">
                  <a
                    onClick={() => {
                      handlePaymentType("MONTH");
                    }}
                    className="theme-btn"
                  >
                    BUY plan
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Plans;
