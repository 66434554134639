import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import SERVER_API from "../constants/apiUrl";
import PageLoader from "../components/PageLoader";
import storage from "../utils/storage";

export default function Users() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const res = await axios.get(`${SERVER_API}/users/all`);
      if (res.data.type === "success") {
        setData(res.data.result);
      }
      setLoading(false);
    };
    if (
      storage.getToken() &&
      storage.decodeToken(storage.getToken()).user_role !== "Admin"
    ) {
      navigate("/");
    } else {
      fetch();
    }
  }, []);

  const handleChangePermission = async (id, active) => {
    const req = {
      id,
      active: !active,
    };
    const res = await axios.post(`${SERVER_API}/users/update`, req);
    if (res.data.type === "success") {
      let temp = data.map((item) => {
        if (item.id === id) return { ...item, active: !item.active };
        else return item;
      });
      setData(temp);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="section banner banner-section">
      <div className="container mt-5" style={{ minHeight: "300px" }}>
        {loading ? (
          <PageLoader />
        ) : (
          <Table>
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>EMAIL</Th>
                <Th>USER ROLE</Th>
                <Th>PERMISSION</Th>
                <Th>CREATED AT</Th>
              </Tr>
            </Thead>
            <Tbody className="mb-5">
              {data?.map((item, idx) => {
                return (
                  <Tr key={idx}>
                    <Td>{idx + 1}</Td>
                    <Td>{item.email}</Td>
                    <Td>{item.role}</Td>
                    <Td>
                      <div className="transparent">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={item.active}
                            onChange={() =>
                              handleChangePermission(item.id, item.active)
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </Td>
                    <Td>{item.created_on.slice(0, 10)}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        )}
      </div>
    </section>
  );
}
