import React, { useEffect, useState, useContext } from "react";
import "../styles/auth.scss";
import { Link, useNavigate } from "react-router-dom";
import storage from "../utils/storage";
import SERVER_API from "../constants/apiUrl";
import authContext from "../context/authContext";
import { toast } from "react-toastify";
import axios from "axios";

const INITIAL_SIGNIN_DATA = {
  email: "",
  password: "",
  signtype: "Email",
};

const Login = () => {
  const [signinData, setSigninData] = useState(INITIAL_SIGNIN_DATA);

  const { setAuth } = useContext(authContext);

  const navigate = useNavigate();
  const user = storage.decodeToken(storage.getToken());
  useEffect(() => {
    if (user) {
      navigate("/results");
    }
  }, []);

  const handleSigninChange = (e) => {
    const { name, value } = e.target;
    setSigninData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSignin = async () => {
    axios
      .post(`${SERVER_API}/api/auth/signin/email`, {
        ...signinData,
      })
      .then((res) => {
        if (res.data.type === "success") {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
          storage.setToken(res.data.token);
          setAuth(storage.decodeToken(storage.getToken()));
          navigate("/");
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((e) => {
        toast.error("Wrong Email or Password!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <main className="section banner banner-section">
      <div className="box login">
        <div className="form">
          <h2>SIGN IN</h2>
          <div className="inputBox">
            <input
              type="text"
              name="email"
              required={true}
              onChange={handleSigninChange}
            />
            <span>Username</span>
            <i></i>
          </div>
          <div className="inputBox">
            <input
              type="password"
              required={true}
              name="password"
              onChange={handleSigninChange}
            />
            <span>Password</span>
            <i></i>
          </div>
          <div className="links">
            <Link to="/forgot-password">Forget password</Link>
            <Link to="/register">Signup</Link>
          </div>
          <input
            type="button"
            className="auth-btn"
            onClick={handleSignin}
            value="Login"
          />
        </div>
      </div>
    </main>
  );
};

export default Login;
