import axios from "axios";
import React, { useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import PageLoader from "../components/PageLoader";
import SERVER_API from "../constants/apiUrl";

export default function Results() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [date, setDate] = useState(
    new Date().getFullYear() +
      "-" +
      String(new Date().getMonth() + 1).padStart(2, "0") +
      "-" +
      String(new Date().getDate()).padStart(2, "0")
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleDateChange = async (e) => {
    setLoading(true);
    setDate(e.target.value);
    await fetchData(e.target.value);
    setLoading(false);
  };

  const fetchData = async (p_date) => {
    const res = await axios.post(`${SERVER_API}/statistics/matchup`, {
      date: p_date,
    });
    if (res.data.type === "success") {
      const uniqueData = unique(
        res.data.data.items.result,
        (a, b) =>
          (a.submenu === b.submenu) & (a.fullmatch_name === b.fullmatch_name)
      );
      setData(uniqueData);
    }
  };

  const unique = (a, fn) => {
    if (a.length === 0 || a.length === 1) {
      return a;
    }
    if (!fn) {
      return a;
    }

    for (let i = 0; i < a.length; i++) {
      for (let j = i + 1; j < a.length; j++) {
        if (fn(a[i], a[j])) {
          a.splice(i, 1);
        }
      }
    }
    return a;
  };

  useEffect(() => {
    const fetch = async () => {
      const res = await axios.post(`${SERVER_API}/statistics/matchup`, {
        date,
      });
      if (res.data.type === "success") {
        const uniqueData = unique(
          res.data.data.items.result,
          (a, b) =>
            (a.submenu === b.submenu) & (a.fullmatch_name === b.fullmatch_name)
        );
        setData(uniqueData);
      }
      setLoading(false);
    };
    fetch();
  }, [date]);

  return (
    <section className="section banner banner-section">
      <div className="container">
        <div className="d-flex form-container justify-content-center">
          <input
            type="date"
            onChange={(e) => handleDateChange(e)}
            value={date}
            className="form-control date-select"
            required
          />
        </div>
        {loading ? (
          <PageLoader />
        ) : (
          <Table>
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>SPORT</Th>
                <Th>TEAMS</Th>
                <Th>TREND</Th>
                <Th>RESULT</Th>
                <Th>LINE</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((item, idx) => {
                const pieces = item.result.split(" ");
                let color = "#faf308bf";
                if (pieces[0] === pieces[2]) {
                  color = "#3fd24bd6";
                } else if (pieces[0] === "9" && pieces[2] === "10") {
                  color = "#FFA500";
                }
                if (item.trend === "Underdog") {
                  color = "#00ff00";
                }
                if (item.line !== null && item.trend !== item.line) {
                  color = "#FF0000";
                }
                return (
                  <Tr key={idx} style={{ backgroundColor: color }}>
                    <Td
                      style={{
                        fontWeight: pieces[0] === pieces[2] ? "bold" : "",
                      }}
                    >
                      {idx + 1}
                    </Td>
                    <Td
                      style={{
                        fontWeight: pieces[0] === pieces[2] ? "bold" : "",
                      }}
                    >
                      {item.submenu}
                    </Td>
                    <Td
                      style={{
                        fontWeight: pieces[0] === pieces[2] ? "bold" : "",
                      }}
                    >
                      {item.fullmatch_name}
                    </Td>
                    <Td
                      style={{
                        fontWeight: pieces[0] === pieces[2] ? "bold" : "",
                      }}
                    >
                      {item.trend}
                    </Td>
                    <Td
                      style={{
                        fontWeight: pieces[0] === pieces[2] ? "bold" : "",
                      }}
                    >
                      {item.result}
                    </Td>
                    <Td
                      className={
                        item.trend === item.line
                          ? "custom-badge badge-success"
                          : item.line !== null
                          ? "custom-badge badge-danger"
                          : "badge-null"
                      }
                      style={{
                        fontWeight: pieces[0] === pieces[2] ? "bold" : "",
                      }}
                    >
                      {item.line}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        )}
      </div>
    </section>
  );
}
