import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import IMGPlaying from "../images/player1.png";
import Plans from "../components/Plans";

export default function Home() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePaymentType = (id) => {
    navigate("/payments", { id });
  };

  return (
    <>
      <main className="main">
        <section className="section banner banner-section">
          <div className="container banner-column">
            <img
              className="banner-image"
              src={IMGPlaying}
              alt="playing"
              height={50}
            />
            <div className="banner-inner">
              <h1 className="heading-xl">
                TALK SPORTS, BEST PREDICTION & RESULTS. WINS!
              </h1>
              <p className="paragraph">
                The fastest, easiest way to bet on sports.NBA, Tennis & Soccer
                etc. <br /> Choose on which team to bet and win!
              </p>
              <button
                className="btn btn-darken btn-inline"
                onClick={() => {
                  navigate("/results");
                }}
              >
                Our Results<i className="bx bx-right-arrow-alt"></i>
              </button>
            </div>
            <div className="banner-links">
              <Link to="/" title="">
                <i className="bx bxl-facebook"></i>
              </Link>
              <Link to="/" title="">
                <i className="bx bxl-instagram"></i>
              </Link>
              <Link to="/" title="">
                <i className="bx bxl-twitter"></i>
              </Link>
              <Link to="/" title="">
                <i className="bx bxl-youtube"></i>
              </Link>
            </div>
          </div>
        </section>
      </main>
      <Plans />
      {/* <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>TALK SPORTS, BEST PREDICTION & RESULTS. WINS!</h1>
              <h3>
                The fastest, easiest way to bet on sports.NBA, Tennis & Soccer
                etc. <br /> Choose on which team to bet and win
              </h3>
              <div className="btn-border">
                <button
                  className="btn btn-start"
                  onClick={() => {
                    navigate("/results");
                  }}
                >
                  Get Started
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bannertop_img">
                <img src="images/index-bg.png" alt="banner" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cosa">
        <div className="container">
          <div className="row">
            <h2>Pick Membership Type</h2>
            <div className="col-md-3">
              <div className="scform">
                <h3 className="info">Most Popular</h3>
                <h2 className="membership-card-title">FREE</h2>
                <h3 className="membership-card-subtitle">subscription</h3>
                <div className="membership-card-description">
                  <div className="chip">unlimited picks</div>
                  <h1 className="price">3 days Trial</h1>
                  <button
                    className="btn btn-select"
                    onClick={() => handlePaymentType(0)}
                  >
                    Select
                  </button>

                  <div className="sports">
                    NFL / NCAAF/ NBA / NCAAB/ MLB / NHL / Soccer
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="scform">
                <h3 className="info">Most Popular</h3>
                <h2 className="membership-card-title">Monthly</h2>
                <h3 className="membership-card-subtitle">subscription</h3>
                <div className="membership-card-description">
                  <div className="chip">unlimited picks</div>
                  <h1 className="price">99$ per month</h1>
                  <button
                    className="btn btn-select"
                    onClick={() => handlePaymentType(1)}
                  >
                    Select
                  </button>

                  <div className="sports">
                    NFL / NCAAF/ NBA / NCAAB/ MLB / NHL / Soccer
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="scform">
                <h3 className="info">Most Popular</h3>
                <h2 className="membership-card-title">Weekly</h2>
                <h3 className="membership-card-subtitle">
                  2-week subscription
                </h3>
                <div className="membership-card-description">
                  <div className="chip">unlimited picks</div>
                  <h1 className="price">30$ per week</h1>
                  <button
                    className="btn btn-select"
                    onClick={() => handlePaymentType(2)}
                  >
                    Select
                  </button>

                  <div className="sports">
                    NFL / NCAAF/ NBA / NCAAB/ MLB / NHL / Soccer
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="scform">
                <h3 className="info">Most Popular</h3>
                <h2 className="membership-card-title">Daily</h2>
                <h3 className="membership-card-subtitle">
                  3-days subscription
                </h3>
                <div className="membership-card-description">
                  <div className="chip">unlimited picks</div>
                  <h1 className="price">5$ per day</h1>
                  <button
                    className="btn btn-select"
                    onClick={() => handlePaymentType(3)}
                  >
                    Select
                  </button>

                  <div className="sports">
                    NFL / NCAAF/ NBA / NCAAB/ MLB / NHL / Soccer
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
