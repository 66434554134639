import React, { useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/footer.scss";
import authContext from "../context/authContext";
import storage from "../utils/storage";

const Header = () => {
  const navigate = useNavigate();
  const burgerRef = useRef(null);
  const menuRef = useRef(null);
  const { auth, setAuth } = useContext(authContext);

  const handleBurgerClick = () => {
    if (menuRef && menuRef.current) {
      menuRef.current.classList.toggle("is-active");
    }
    if (burgerRef && burgerRef.current) {
      burgerRef.current.classList.toggle("is-active");
    }
  };

  return (
    <header className="header" id="header">
      <nav className="navbar container">
        <Link to="/" className="brand">
          Trend10
        </Link>
        <div className="burger" onClick={handleBurgerClick} ref={burgerRef}>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
        </div>
        <div className="menu" id="menu" ref={menuRef}>
          <ul className="menu-inner">
            <li className="menu-item">
              <Link to="/" className="menu-link">
                Home
              </Link>
            </li>
            {auth ? (
              <>
                <li className="menu-item">
                  <Link to="/results" className="menu-link">
                    Results
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/statistics" className="menu-link">
                    Statistics
                  </Link>
                </li>
                {storage.getToken() &&
                  storage.decodeToken(storage.getToken()).user_role ===
                    "Admin" && (
                    <li className="menu-item">
                      <Link to="/users" className="menu-link">
                        Users
                      </Link>
                    </li>
                  )}
              </>
            ) : null}
          </ul>
        </div>
        {auth ? (
          <button
            onClick={() => {
              storage.clearToken();
              setAuth(null);
            }}
            className="menu-block"
          >
            Logout
          </button>
        ) : (
          <button
            onClick={() => {
              navigate("/login");
            }}
            className="menu-block"
          >
            Join
          </button>
        )}
      </nav>
    </header>
  );
};

export default Header;
