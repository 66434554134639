import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authContext from "./context/authContext";
import Home from "./pages/home";
import Results from "./pages/results";
import Statistics from "./pages/statistics";
import Users from "./pages/users";
import Profile from "./pages/profile";
import Payments from "./pages/payments";
import Footer from "./components/NFooter";
import ProtectedRoute from "./components/ProtectedRoute";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { useEffect } from "react";
import storage from "./utils/storage";
import Header from "./components/NHeader";
import "./styles/header.scss";
import "./styles/boxicons.min.css";
import "./styles/main.scss";
import Login from "./pages/login";
import Register from "./pages/register";
import GoTop from "./components/GoTop";
import "./App.css";

function App() {
  const [auth, setAuth] = useState();

  useEffect(() => {
    const token = storage.getToken();
    const res = storage.decodeToken(token);
    setAuth(res);
  }, []);

  return (
    <authContext.Provider value={{ auth, setAuth }}>
      <Router>
        <Fragment>
          <GoTop scrollStepInPx="100" delayInMs="10.50" />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/results"
              element={
                <ProtectedRoute>
                  <Results />
                </ProtectedRoute>
              }
            />
            <Route
              path="/statistics"
              element={
                <ProtectedRoute>
                  <Statistics />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route path="/payments" element={<Payments />} />
          </Routes>
          <Footer />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Fragment>
      </Router>
    </authContext.Provider>
  );
}

export default App;
