import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import storage from "../utils/storage";
// import { toast } from "react-toastify";

const ProtectedRoute = ({ children }) => {
  if (!storage.getToken()) {
    return <Navigate to="/" replace />;
  } else if (storage.getToken()) {
    return children ? children : <Outlet />;
    // const user = storage.decodeToken(storage.getToken());
    // const diffDays =
    //   (new Date() - new Date(user.membership_date)) / (1000 * 3600 * 24);
    // if (user.user_role === "Admin") {
    //   return children ? children : <Outlet />;
    // } else if (!diffDays) {
    //   <Navigate to="/payments" replace />;
    // } else if (user.user_active === 0) {
    //   toast.warning("You are not allowed, please contact with administrator.", {
    //     position: "top-center",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     theme: "colored",
    //   });
    //   return <Navigate to="/" replace />;
    // } else if (user.membership_type === "FREE" && diffDays > 3) {
    //   toast.warning(
    //     "You are using Free Membership now. Please upgrade your membership.",
    //     {
    //       position: "top-center",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       theme: "colored",
    //     }
    //   );
    //   return <Navigate to="/payments" replace />;
    // } else if (user.membership_type === "DAILY" && diffDays > 1) {
    //   toast.warning(
    //     "YYour deaily membership is expired. Please upgrade your membership.",
    //     {
    //       position: "top-center",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       theme: "colored",
    //     }
    //   );
    //   return <Navigate to="/payments" replace />;
    // } else if (user.membership_type === "WEEKLY" && diffDays > 7) {
    //   toast.warning(
    //     "Your weekly membership is expired. Please upgrade your membership.",
    //     {
    //       position: "top-center",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       theme: "colored",
    //     }
    //   );
    //   return <Navigate to="/payments" replace />;
    // } else if (user.membership_type === "MONTHLY" && diffDays > 20) {
    //   toast.warning(
    //     "Your monthly membership is expired. Please upgrade your membership.",
    //     {
    //       position: "top-center",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       theme: "colored",
    //     }
    //   );
    //   return <Navigate to="/payments" replace />;
    // } else {
    //   return children ? children : <Outlet />;
    // }
  }
};

export default ProtectedRoute;
