import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import axios from "axios";
import PageLoader from "../components/PageLoader";
import SERVER_API from "../constants/apiUrl";

export default function Statistics() {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  const currentDay = today.getDate();
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(``);
  const [endDate, setEndDate] = useState(
    `${currentYear}-${currentMonth > 9 ? currentMonth : "0" + currentMonth}-${
      currentDay > 9 ? currentDay : "0" + currentDay
    }`
  );
  const [type, setType] = useState("NCAAB");
  const [filterBy, setFilterBy] = useState("SEASON");
  const [date, setDate] = useState(
    `${currentYear}-${currentMonth > 9 ? currentMonth : "0" + currentMonth}-${
      currentDay > 9 ? currentDay : "0" + currentDay
    }`
  );
  const [month, setMonth] = useState(
    `${currentYear}-${currentMonth > 9 ? currentMonth : "0" + currentMonth}`
  );
  const [week, setWeek] = useState(
    `${currentYear}-W${Math.ceil(
      ((new Date() - new Date(currentYear, 0, 1)) / 86400000 + 1) / 7
    )}`
  );
  const [loading, setLoading] = useState(true);

  const getDateOfISOWeek = (w, y) => {
    const start = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = start.getDay();
    let ISOweekStart = start;
    if (dow <= 4) ISOweekStart.setDate(start.getDate() - start.getDay() + 1);
    else ISOweekStart.setDate(start.getDate() + 8 - start.getDay());
    return ISOweekStart.toISOString();
  };

  const getEndDateOfISOWeek = (w, y) => {
    const end = new Date(y, 0, w * 7);
    const dow = end.getDay();
    let ISOweekStart = end;
    if (dow <= 4) ISOweekStart.setDate(end.getDate() - end.getDay() + 1);
    else ISOweekStart.setDate(end.getDate() + 8 - end.getDay());
    return ISOweekStart.toISOString();
  };

  useEffect(() => {
    const mountFetch = async () => {
      await handleFetchData(
        "",
        `${currentYear}-${
          currentMonth > 9 ? currentMonth : "0" + currentMonth
        }-${currentDay > 9 ? currentDay : "0" + currentDay}`,
        type
      );
    };
    mountFetch();
  }, []);

  const fetchData = async (req) => {
    const res = await axios.post(`${SERVER_API}/statistics/params`, req);
    return res.data;
  };

  const handleWeekChange = async (e) => {
    e.preventDefault();
    setWeek(e.target.value);
    const start = getDateOfISOWeek(
      Number(
        e.target.value.slice(e.target.value.length - 2, e.target.value.length)
      ),
      Number(e.target.value.slice(0, 4))
    ).slice(0, 10);
    const end = getEndDateOfISOWeek(
      Number(
        e.target.value.slice(e.target.value.length - 2, e.target.value.length)
      ),
      Number(e.target.value.slice(0, 4))
    ).slice(0, 10);
    setStartDate(start);
    setEndDate(end);
    handleFetchData(start, end, type);
  };

  const handleChangeMonth = async (e) => {
    e.preventDefault();
    setMonth(e.target.value);
    const year = parseInt(e.target.value.split("-")[0]);
    const month = parseInt(e.target.value.split("-")[1]);
    const startOfMonth = new Date(year, month, 1);
    const endOfMonth = new Date(year, month, 0);
    const start = `${startOfMonth.getFullYear()}-${
      startOfMonth.getMonth() > 9
        ? startOfMonth.getMonth()
        : "0" + startOfMonth.getMonth()
    }-${
      startOfMonth.getDate() > 9
        ? startOfMonth.getDate()
        : "0" + startOfMonth.getDate()
    }`;
    const end = `${endOfMonth.getFullYear()}-${
      endOfMonth.getMonth() + 1 > 9
        ? endOfMonth.getMonth() + 1
        : "0" + (endOfMonth.getMonth() + 1)
    }-${
      endOfMonth.getDate() > 9
        ? endOfMonth.getDate()
        : "0" + endOfMonth.getDate()
    }`;
    setStartDate(start);
    setEndDate(end);
    await handleFetchData(start, end, type);
  };

  const handleSelectChange = async (e) => {
    const t = e.target.value;
    setType(t);
    await handleFetchData(startDate, endDate, t);
  };

  const handleSelectFilterTypeChange = async (e) => {
    e.preventDefault();
    setFilterBy(e.target.value);
    if (e.target.value === "DAY") {
      const value = `${currentYear}-${
        currentMonth > 9 ? currentMonth : "0" + currentMonth
      }-${currentDay > 9 ? currentDay : "0" + currentDay}`;
      setDate(value);
      setStartDate(value);
      setEndDate(value);
      await handleFetchData(value, value, type);
    } else if (e.target.value === "MONTH") {
      const mon = `${currentYear}-${
        currentMonth > 9 ? currentMonth : "0" + currentMonth
      }`;
      setMonth(mon);
      const year = parseInt(mon.split("-")[0]);
      const month = parseInt(mon.split("-")[1]);
      const startOfMonth = new Date(year, month, 1);
      const endOfMonth = new Date(year, month, 0);
      const start = `${startOfMonth.getFullYear()}-${
        startOfMonth.getMonth() > 9
          ? startOfMonth.getMonth()
          : "0" + startOfMonth.getMonth()
      }-${
        startOfMonth.getDate() > 9
          ? startOfMonth.getDate()
          : "0" + startOfMonth.getDate()
      }`;
      const end = `${endOfMonth.getFullYear()}-${
        endOfMonth.getMonth() + 1 > 9
          ? endOfMonth.getMonth() + 1
          : "0" + (endOfMonth.getMonth() + 1)
      }-${
        endOfMonth.getDate() > 9
          ? endOfMonth.getDate()
          : "0" + endOfMonth.getDate()
      }`;
      setStartDate(start);
      setEndDate(end);
      await handleFetchData(start, end, type);
    } else if (e.target.value === "WEEK") {
      const weekVal = `${currentYear}-W${Math.ceil(
        ((new Date() - new Date(currentYear, 0, 1)) / 86400000 + 1) / 7
      )}`;
      setWeek(weekVal);
      const start = getDateOfISOWeek(
        Number(weekVal.slice(weekVal.length - 2, weekVal.length)),
        Number(weekVal.slice(0, 4))
      ).slice(0, 10);
      const end = getEndDateOfISOWeek(
        Number(weekVal.slice(weekVal.length - 2, weekVal.length)),
        Number(weekVal.slice(0, 4))
      ).slice(0, 10);
      setStartDate(start);
      setEndDate(end);
      handleFetchData(start, end, type);
    } else if (e.target.value === "SEASON") {
      setStartDate("");
      setEndDate(
        `${currentYear}-${
          currentMonth > 9 ? currentMonth : "0" + currentMonth
        }-${currentDay > 9 ? currentDay : "0" + currentDay}`
      );
      await handleFetchData(
        "",
        `${currentYear}-${
          currentMonth > 9 ? currentMonth : "0" + currentMonth
        }-${currentDay > 9 ? currentDay : "0" + currentDay}`,
        type
      );
    }
  };

  const handleChangeDay = async (e) => {
    e.preventDefault();
    setStartDate(e.target.value);
    setDate(e.target.value);
    setEndDate(e.target.value);
    await handleFetchData(e.target.value, e.target.value, type);
  };

  const handleFetchData = async (start, end, type) => {
    setLoading(true);
    const res = await fetchData({
      start: start ? start : "2023-09-01",
      end,
      type,
    });
    setData(res.data.items);
    console.log(res.data);
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="section banner banner-section">
      <div className="container">
        <div className="form-container">
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={handleSelectFilterTypeChange}
            value={filterBy}
          >
            <option value="SEASON">SEASON</option>
            <option value="MONTH">MONTH</option>
            <option value="WEEK">WEEK</option>
            <option defaultValue="DAY">DAY</option>
          </select>
          {filterBy === "DAY" && (
            <input
              type="date"
              className="form-control"
              id="start"
              name="trip-start"
              onChange={handleChangeDay}
              value={date}
            />
          )}
          {filterBy === "WEEK" && (
            <input
              type="week"
              className="form-control week-select"
              onChange={handleWeekChange}
              value={week}
            />
          )}
          {filterBy === "MONTH" && (
            <input
              type="month"
              className="form-control"
              onChange={handleChangeMonth}
              value={month}
            />
          )}
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={handleSelectChange}
          >
            <option value="NCAAB">NCAAB</option>
            <option value="NFL">NFL</option>
            <option value="NCAAF">NCAAF</option>
            <option value="NBA">NBA</option>
            <option value="MLB">MLB</option>
            <option value="NHL">NHL</option>
            <option value="SOCCER">SOCCER</option>
          </select>
        </div>
        {loading ? (
          <PageLoader />
        ) : (
          <Tabs>
            <TabList>
              <Tab>Favorite</Tab>
              <Tab>Underdog</Tab>
              <Tab>Over</Tab>
              <Tab>Under</Tab>
            </TabList>

            <TabPanel>
              <Table>
                <Thead>
                  <Tr>
                    <Th>No</Th>
                    <Th>Kind</Th>
                    <Th>Total Count</Th>
                    <Th>Count</Th>
                    <Th>Percent</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.resultFavorite.map((item, idx) => {
                    return (
                      <Tr key={idx}>
                        <Td>{idx + 1}</Td>
                        <Td>{item.result}</Td>
                        <Td>{item.total_cnt}</Td>
                        <Td>{item.real_cnt}</Td>
                        <Td>
                          {((item.real_cnt / item.total_cnt) * 100).toFixed(2)}{" "}
                          %
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TabPanel>
            <TabPanel>
              <Table>
                <Thead>
                  <Tr>
                    <Th>No</Th>
                    <Th>Kind</Th>
                    <Th>Total Count</Th>
                    <Th>Count</Th>
                    <Th>Percent</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.resultUnderdog.map((item, idx) => {
                    return (
                      <Tr key={idx}>
                        <Td>{idx + 1}</Td>
                        <Td>{item.result}</Td>
                        <Td>{item.total_cnt}</Td>
                        <Td>{item.real_cnt}</Td>
                        <Td>
                          {((item.real_cnt / item.total_cnt) * 100).toFixed(2)}{" "}
                          %
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TabPanel>
            <TabPanel>
              <Table>
                <Thead>
                  <Tr>
                    <Th>No</Th>
                    <Th>Kind</Th>
                    <Th>Total Count</Th>
                    <Th>Count</Th>
                    <Th>Percent</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.resultOver.map((item, idx) => {
                    return (
                      <Tr key={idx}>
                        <Td>{idx + 1}</Td>
                        <Td>{item.result}</Td>
                        <Td>{item.total_cnt}</Td>
                        <Td>{item.real_cnt}</Td>
                        <Td>
                          {((item.real_cnt / item.total_cnt) * 100).toFixed(2)}{" "}
                          %
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TabPanel>
            <TabPanel>
              <Table>
                <Thead>
                  <Tr>
                    <Th>No</Th>
                    <Th>Kind</Th>
                    <Th>Total Count</Th>
                    <Th>Count</Th>
                    <Th>Percent</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.resultUnder.map((item, idx) => {
                    return (
                      <Tr key={idx}>
                        <Td>{idx + 1}</Td>
                        <Td>{item.result}</Td>
                        <Td>{item.total_cnt}</Td>
                        <Td>{item.real_cnt}</Td>
                        <Td>
                          {((item.real_cnt / item.total_cnt) * 100).toFixed(2)}{" "}
                          %
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TabPanel>
          </Tabs>
        )}
      </div>
    </section>
  );
}
