import React from "react";
import "../styles/icofont.min.css";

const GoTop = () => {
  const [thePosition, setThePosition] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        setThePosition(true);
      } else {
        setThePosition(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const renderGoTopIcon = () => {
    return (
      <div
        className={`go-top ${thePosition ? "active" : ""}`}
        onClick={scrollToTop}
      >
        <i className="icofont-hand-drawn-up"></i>
      </div>
    );
  };

  return <>{renderGoTopIcon()}</>;
};

export default GoTop;
