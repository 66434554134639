import jwt_decode from 'jwt-decode';
const storagePrefix = 'Bearer ';

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`));
  },
  setToken: (token) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  decodeToken: (token) => {
    if (token) {
      return jwt_decode(token);
    } else {
      return false;
    }
  }
};

export default storage;
