import React, { useEffect, useState } from "react";
import "../styles/auth.scss";
import { Link, useNavigate } from "react-router-dom";
import storage from "../utils/storage";
import SERVER_API from "../constants/apiUrl";
import { toast } from "react-toastify";
import axios from "axios";

const INITIAL_SIGNUP_DATA = {
  email: "",
  password: "",
  confirmPassword: "",
  signtype: "Email",
};

const Register = () => {
  const [error, setError] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [signupData, setSignupData] = useState(INITIAL_SIGNUP_DATA);
  const navigate = useNavigate();
  const user = storage.decodeToken(storage.getToken());
  useEffect(() => {
    if (user) {
      navigate("/results");
    }
  }, []);

  const handleSignupChange = (e) => {
    const { name, value } = e.target;
    setSignupData((prevState) => ({ ...prevState, [name]: value }));
    validateInput(e);
  };

  const handleSignup = async () => {
    const { password, confirmPassword } = signupData;
    if (password === confirmPassword) {
      axios
        .post(`${SERVER_API}/api/auth/signup/email`, {
          ...signupData,
        })
        .then((res) => {
          if (res.data.type === "success") {
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });
            navigate("/login");
          } else {
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        })
        .catch((e) => {
          toast.error("Somethign went wrong. try again after 1 minute.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (email) => {
    return String(email).match(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
    );
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "email":
          if (!validateEmail(value)) {
            stateObj[name] = "Please enter email.";
          }
          break;
        case "password":
          if (!validatePassword(value)) {
            stateObj[name] =
              "Your password must be 8-16 characters, and include at least one lowercase, one uppercase letter and a number.";
          } else if (
            signupData.confirmPassword &&
            value !== signupData.confirmPassword
          ) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = signupData.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;
        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (signupData.password && value !== signupData.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;
        default:
          break;
      }
      return stateObj;
    });
  };

  return (
    <main className="section banner banner-section">
      <div class="box">
        <div class="form">
          <h2>SIGN UP</h2>
          <div class="inputBox">
            <input
              type="email"
              name="email"
              onChange={handleSignupChange}
              required={true}
            />
            <span>Email</span>
            <i></i>
          </div>
          {error.email && <p className="err">{error.email}</p>}
          <div class="inputBox">
            <input
              type="password"
              name="password"
              onChange={handleSignupChange}
              required
            />
            <span>Password</span>
            <i></i>
          </div>
          {error.password && <p className="err">{error.password}</p>}
          <div class="inputBox">
            <input
              onChange={handleSignupChange}
              type="password"
              name="confirmPassword"
              required={true}
            />
            <span>Password</span>
            <i></i>
          </div>
          {error.confirmPassword && (
            <p className="err">{error.confirmPassword}</p>
          )}
          <div class="links">
            <Link to="/login">Already have account?</Link>
            <Link to="/login">Log In</Link>
          </div>
          <input
            type="button"
            className="auth-btn"
            onClick={handleSignup}
            value="Sign Up"
          />
        </div>
      </div>
    </main>
  );
};

export default Register;
